import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon, UserIcon, MagnifyingGlassIcon, ShoppingBagIcon, ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useGeral } from "../../context/GeralContext"

import logo from '../../assets/img-skeleton.png'
import "./Header.css";
import { useCheckout } from "../../context/CheckoutContext";
import { useTranslation } from "react-i18next";

function Header(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [openHeader, setOpenHeader] = useState<boolean>(true)
  const [userSideOpen, setUserSideOpen] = useState<boolean>(false)
  const [userSideBack, setUserSideBack] = useState<boolean>(true)
  const [openSearchSideBar, setOpenSearchSideBar] = useState<boolean>(false)
  const [querySearch, setQuerySearch] = useState("")
  const [searchLoading, setSearchLoading] = useState<boolean>(true)
  const [searchBarList, setSearchBarList] = useState([{}])
  const [filters, setFilters] = useState<any>(null)
  const { cartQuantity, itemAddedToCart, openCart, openLogin, userData, logOut } = useShoppingCart()
  const { tokenUser, userNotifications } = useGeral()
  const { checkoutGuest } = useCheckout()
  const [scrollY, setScrollY] = useState<number>(0)
  const [confirmCheckoutModal, setConfirmCheckoutModal] = useState<boolean>(false)
  const [expandeMobileMenu, setExpandeMobileMenu] = useState<boolean>(false)

  useEffect(() => {
    document.querySelector(".shop_subheader")?.addEventListener("mouseover", () => {
      setOpenHeader(true)
    });
    setSearchLoading(true)
    const typing = setTimeout(() => {
      if (querySearch.length > 3) {
        openSearchSideBarModal()

        var myHeaders = new Headers();
        if (tokenUser) {
          myHeaders.append("Authorization", "Bearer " + tokenUser);
        }
        myHeaders.append("Content-Type", "application/json");

        var requestOptions: RequestInit = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/products/listings/all?limit=0&q=' + querySearch + '&id_brand=' + process.env.REACT_APP_ID_BRAND, requestOptions)
          .then(response => response.json())
          .then(result => {
            result.products_listings.sort(function (a, b) { return a.min_price - b.min_price; });
            setSearchBarList(result.products_listings)
            setFilters(result.filters)
            setSearchLoading(false)
          })
          .catch(error => setSearchBarList([]));
      } else {
        setSearchLoading(true)
        setSearchBarList([])
      }
    }, 500);
    return () => clearTimeout(typing)
  }, [querySearch])

  window.onscroll = function () {
    setScrollY(window.scrollY)
  }
  const onChangeSideOpen = () => {
    if (location.pathname.startsWith("/user")) {
      setUserSideOpen(true);
    }

    setUserSideBack(true);
    if (open) {
      setUserSideOpen(false);
    }
    setOpen(!open)
    if (!open) {
      document.getElementsByTagName('body')[0].classList.add('modal-open')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }
  }
  function closeSideBarHeader() {
    setOpen(false)
    setUserSideOpen(false)
    closeSearchSideBarModal()
    document.getElementsByTagName('body')[0].classList.remove('modal-open')
  }
  function openSearchSideBarModal() {
    setOpenSearchSideBar(true)
    document.getElementById('SearchMobile')?.focus()
    document.getElementsByTagName('body')[0].classList.add('modal-open-mobile')
    document.getElementsByTagName('body')[0].classList.add('modal-open')
  }
  function closeSearchSideBarModal() {
    setOpenSearchSideBar(false)
    document.getElementsByTagName('body')[0].classList.remove('modal-open-mobile')
    document.getElementsByTagName('body')[0].classList.remove('modal-open')
  }

  const onChangeUserSideOpen = () => {
    setUserSideOpen(!userSideOpen);
  }
  const onChangeExpandeMobileMenu = () => {
    setExpandeMobileMenu(!expandeMobileMenu);
  }
  function openBLosersPage() {
    if(expandeMobileMenu) {
      onChangeSideOpen()
      navigate("/shop-beautiful-losers")
    }
  }
  const onUserClick = () => {
    if (!tokenUser || checkoutGuest) {
      openLogin();
    } else {
      navigate("/user")
    }
  }
  function closeSideBarHeaderOverlay(e) {
    if (process.env.REACT_APP_SEARCH_OP == "overlay" && open) {
      e.stopPropagation();
      closeSideBarHeader()
    }
  }
  function goToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  function logoClick() {
    if (window.location.pathname == "/checkout" || window.location.pathname == "/review" || window.location.pathname == "/payment") {
      setConfirmCheckoutModal(true)
      document.getElementsByTagName('body')[0].classList.add('modal-open')
    } else {
      navigate("/")
    }
  }
  function closeConfirmModal() {
    setConfirmCheckoutModal(false)
    document.getElementsByTagName('body')[0].classList.remove('modal-open')
  }

  return (
    <div className={"header " + (window.location.pathname === "/" ? "homepage" : "")}>
      <header className={"header_wrapper w-full z-40 " + (openSearchSideBar ? "search_modal_open " : "") + (window.location.pathname === "/" ? (scrollY <= 5 ? "no_scrollable home " : "scrollable") : (scrollY > 5 ? "scrollable" : "no_scrollable"))}>
        <div className="relative header_container w-full items-center font-medium px-4 sm:px-6 lg:px-8" onClick={(e) => closeSideBarHeaderOverlay(e)}>
          {process.env.REACT_APP_THEME == "theme1" ? <div className="header_logo p-2 w-auto cursor-pointer" onClick={logoClick}>
            <img onClick={goToTop} src={window.location.pathname === "/" && scrollY <= 5 && !openSearchSideBar && process.env.REACT_APP_2HDICON_WHITE ? process.env.REACT_APP_2HDICON_WHITE : process.env.REACT_APP_2HDICON} width="120px" height="25px" alt="logo" />
          </div> :
            <div className="header_logo m-2 w-auto cursor-pointer" onClick={logoClick}>
              <img src={process.env.REACT_APP_2HDICON} width="120px" height="25px" alt="logo" />
            </div>}
          <div className="h-full header_pages hidden md:flex flex-row items-center w-auto">
            {process.env.REACT_APP_ID_BRAND === '4' ? <ul className="h-full w-auto flex uppercase items-center focus:delay-150">
              <li key="clothing" className="relative flex items-center mr-2">
                <Link to={"/clothing"} className="no-underline"><p>{t('categories.clothing')}</p></Link>
              </li>
              <li key="accessories" className="relative flex items-center mr-2">
                <Link to={"/accessories"} className="no-underline"><p>{t('categories.accessories')}</p></Link>
              </li>
              <li key="about" className="relative flex items-center mr-2">
                <Link to={"/about"} className="no-underline"><p>{t('pages.about')}</p></Link>
              </li>
            </ul> :
              process.env.REACT_APP_ID_BRAND === '5' ? <ul className="h-full w-auto flex uppercase items-center focus:delay-150">
                <li key="shop" className="h-full group flex items-center mr-2">
                  <Link to={"/all"} className="shop_subheader relative no-underline"><p>{t('categories.shop')}</p></Link>
                  {openHeader && <div className="header_pages !w-full absolute hidden group-hover:flex w-full top-[var(--header-height)] left-0 bg-white py-2 px-4 sm:px-6 lg:px-8">
                    <ul className="h-full flex flex-col uppercase focus:delay-150">
                      <li className="w-fit py-2 uppercase">
                        <Link onClick={() => setOpenHeader(false)} to={'/all'} className="relative no-underline"><p>{t('categories.all')}</p></Link>
                      </li>
                      <li className="w-fit py-2 uppercase">
                        <Link onClick={() => setOpenHeader(false)} to={'/shop-pre-loved'} className="relative no-underline"><p>{t('categories.second_hand')}</p></Link>
                      </li>
                      <li className="w-fit py-2 uppercase">
                        <Link to={'/shop-beautiful-losers'} className="relative no-underline"><p>{t('categories.blosers')}</p></Link>
                        {props?.brandDetails?.menu && <div className="mt-2 ml-3">
                          <ul className="!gap-1 h-full flex flex-col uppercase focus:delay-150">
                            {props?.brandDetails?.menu?.map((cat) => (
                              <li className="w-fit py-2 uppercase">
                                <Link onClick={() => setOpenHeader(false)} to={'/shop-beautiful-losers?cat=' + cat.id_category} className="relative no-underline"><p>{cat.category_description}</p></Link>
                              </li>
                            ))}
                          </ul>
                        </div>}
                      </li>
                    </ul>
                  </div>}
                </li>
                <li key="sell" className="relative flex items-center mr-2">
                  <Link to={"/sell"} className="no-underline"><p>{t('categories.sell')}</p></Link>
                </li>
                <li key="about" className="relative flex items-center mr-2">
                  <Link to={"/about"} className="no-underline"><p>{t('pages.about')}</p></Link>
                </li> 
                <li key="brand" className="relative flex items-center mr-2">
                  <a href={'https://' + process.env.REACT_APP_BRAND_WEBSITE} className="no-underline"><p>{process.env.REACT_APP_BRAND_WEBSITE}</p></a>
                </li>
              </ul> :
                <ul className="h-full w-auto flex uppercase items-center focus:delay-150">
                  <li key="women" className="relative flex items-center mr-2">
                    <Link to={"/women"} className="no-underline"><p>{t('categories.women')}</p></Link>
                  </li>
                  <li key="men" className="relative flex items-center mr-2">
                    <Link to={"/men"} className="no-underline"><p>{t('categories.men')}</p></Link>
                  </li>
                  <li key="about" className="relative flex items-center mr-2">
                    <Link to={"/about"} className="no-underline"><p>{t('pages.about')}</p></Link>
                  </li>
                </ul>
            }
            {process.env.REACT_APP_SEARCH_OP === "static" && <div className="header_search hidden md:flex relative w-full flex items-center font-normal">
              <input autoFocus maxLength={50} id="SearchMobile" type="search" placeholder={process.env.REACT_APP_THEME == 'theme3' ? t('header.search') : t('header.search_prelove_item')} className={"searchbar_form bg-transparent w-full outline-none pr-6 " + (openSearchSideBar || scrollY > 5 || window.location.pathname !== "/" ? 'on_focus' : '')}
                value={querySearch}
                onChange={event => setQuerySearch(event.target.value)} />
              <button className="absolute right-0 m-2"><MagnifyingGlassIcon className="w-5 h-5"></MagnifyingGlassIcon></button>
            </div>}
          </div>
          <div className="header_burguer flex md:hidden flex-row items-center w-auto cursor-pointer">
            <Bars3Icon className="block h-9 w-6" aria-hidden="true" onClick={() => onChangeSideOpen()} />
          </div>
          <div className="header_user flex justify-end w-auto">
            <div className="header_user_container flex">
              {/* {props.brandDetails?.main_domain && <a aria-label='back-store' className="header_back_store hidden lg:flex text-sm whitespace-nowrap" href={props.brandDetails?.main_domain}>Back to {(new URL(props.brandDetails?.main_domain)).hostname}</a>} */}
              <button title={t('product.add_wishlist')} className={"header_search p-1 relative flex justify-center items-center " + (process.env.REACT_APP_SEARCH_OP === "static" ? 'flex md:hidden' : 'flex')} onClick={openSearchSideBarModal} >
                <MagnifyingGlassIcon className="h-6 w-6 ml-1 cursor-pointer pointer-events-none flex " aria-hidden="true" />
              </button>
              <button id="customer" title={t('header.my_account')} className="relative p-1 header-icon"
                onClick={() => onUserClick()}>
                <span className="sr-only">{t('header.my_account')}</span>
                <UserIcon className="h-6 w-6" aria-hidden="true" />
                {tokenUser && userNotifications > 0 && <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[var(--bg-cart-wishlist-count)] border-2 border-white rounded-full -top-2 -right-2 transition duration-500 ease-in-out transform">{userNotifications}</div>}
              </button>
              <button id="cart" title={t('header.my_bag')} type="button" className="relative p-1 header-icon" onClick={() => openCart()} >
                <span className="sr-only">{t('header.my_bag')}</span>
                <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                <div className={"absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[var(--bg-cart-wishlist-count)] border-2 border-white rounded-full -top-2 -right-2 transition duration-500 ease-in-out transform " + (itemAddedToCart ? "-translate-y-1 scale-110" : "")}>{cartQuantity}</div>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile nav */}
        <div className={"header_left_container md:hidden bg-white z-[55] fixed w-full h-full text-[var(--primary-color)] font-normal top-0 overflow-hidden bottom-0 duration-500 " + (open ? "modal-open left-0" : "left-[-100%]")}>
          <div className="header_left_wrapper h-full divide-y divide-gray-200 inset-0">
            <div className="header flex justify-between items-center h-16">
              <img className="px-3 w-auto h-[35px]" src={props.brandDetails?.mini_logo} alt="mini_logo" />
              <button onClick={() => onChangeSideOpen()} ><XMarkIcon className="mr-3 h-5 w-5" aria-hidden="true" /></button>
            </div>
            <div className="content h-full flex flex-col divide-y divide-gray-200 overflow-x-hidden overflow-y-auto">
              <div className="pages flex flex-col py-4 px-3">
                <div className="sub_title text-sm text-gray-500 pb-3">Categories</div>
                {process.env.REACT_APP_ID_BRAND === '4' ? <ul className="uppercase">
                  <li className="page_category">
                    <Link to={"/clothing"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                      <p>{t('categories.clothing')}</p>
                    </Link>
                  </li>
                  <li className="page_category">
                    <Link to={"/accessories"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                      <p>{t('categories.accessories')}</p>
                    </Link>
                  </li>
                  <li className="page_category">
                    <Link to={"/about"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                      <p>{t('pages.about')}</p>
                    </Link>
                  </li>
                </ul> :
                  process.env.REACT_APP_ID_BRAND === '5' ? <ul className="uppercase">
                    <li className="page_category relative flex items-center mr-2">
                      <Link to={'/all'} onClick={() => onChangeSideOpen()} className="relative no-underline"><p>{t('categories.all')}</p></Link>
                    </li>
                    <li className="page_category relative flex items-center mr-2">
                      <Link to={'/shop-pre-loved'} onClick={() => onChangeSideOpen()} className="relative no-underline"><p>{t('categories.second_hand')}</p></Link>
                    </li>
                    <li className="page_category relative flex flex-col mr-2">
                      <div onClick={() => onChangeExpandeMobileMenu()} className="relative no-underline w-full flex justify-between">
                        <p onClick={() => openBLosersPage()}>{t('categories.blosers')}</p>
                        {expandeMobileMenu ? <ChevronUpIcon className="h-5 w-5 inline-flex" /> : <ChevronDownIcon className="h-5 w-5 inline-flex" />}
                      </div>
                      {props?.brandDetails?.menu && <div className={"teste mt-2 ml-3 " + (expandeMobileMenu ? "active flex" : "hidden")}>
                        <ul className="!gap-1 w-full h-full flex flex-col uppercase focus:delay-150">
                          {props?.brandDetails?.menu?.map((cat) => (
                            <li className="py-2 uppercase">
                              <Link onClick={() => onChangeSideOpen()} to={'/shop-beautiful-losers?cat=' + cat.id_category} className="relative no-underline"><p>{cat.category_description}</p></Link>
                            </li>
                          ))}
                        </ul>
                      </div>}
                    </li>
                    <li key="sell" className="page_category relative flex items-center mr-2">
                      <Link to={"/sell"} className="no-underline" onClick={() => onChangeSideOpen()}><p>{t('categories.sell')}</p></Link>
                    </li>
                    <li key="about" className="page_category relative flex items-center mr-2">
                      <Link to={"/about"} onClick={() => onChangeSideOpen()}><p>{t('pages.about')}</p></Link>
                    </li>
                  </ul> :
                    <ul className="uppercase">
                      <li className="page_category">
                        <Link to={"/women"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                          <p>{t('categories.women')}</p>
                        </Link>
                      </li>
                      <li className="page_category">
                        <Link to={"/men"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                          <p>{t('categories.men')}</p>
                        </Link>
                      </li>
                      <li className="page">
                        <Link to={"/about"} className="flex justify-between" onClick={() => onChangeSideOpen()}>
                          <p>{t('pages.about')}</p>
                        </Link>
                      </li>
                    </ul>
                }
              </div>

              <div className="my_account_block h-full flex flex-col justify-between py-4 px-3 gap-y-3">
                <div className="flex flex-col gap-y-3">
                  <div className="sub_title text-sm text-gray-500">{t('header.my_account')}</div>

                  {/* logout */}
                  {!(tokenUser && !checkoutGuest) ? <div className="flex flex-col">
                    <button id="sign_in" className="primary-button" onClick={() => onUserClick()}>{t('header.sign_in')}</button>
                    {/* <button className="border border-black p-3" onClick={() => onUserClick()}>Register</button> */}
                  </div>
                    :
                    <div className="flex flex-col gap-3">
                      <button className="user_details flex justify-between items-center py-3" onClick={onChangeUserSideOpen}>
                        <div className="flex flex-row gap-x-1 items-center">
                          <UserIcon className="header_icon h-6 w-6 inline-flex"></UserIcon>
                          <p>{userData?.customer?.FirstName} {userData?.customer?.LastName}</p>
                        </div>
                        <ChevronRightIcon className="h-5 w-5 inline-flex" />
                      </button>
                      <button id="sign_out" className="secundary-button" onClick={() => { logOut(); closeSideBarHeader(); }}>{t('header.sign_out')}</button>
                    </div>}
                </div>
                <div className="w-full flex">
                  {tokenUser ? <Link to="/user/submit-item-2hand" onClick={() => closeSideBarHeader()} className="w-full primary-extra-button uppercase flex justify-center font-medium">{t('home.sell')}</Link> :
                    <button onClick={() => { openLogin(); closeSideBarHeader() }} className="w-full primary-extra-button uppercase flex justify-center font-medium">{t('home.sell')}</button>}
                  {/* <a aria-label='sell' className="primary-extra-button font-medium flex justify-center" href={props.brandDetails?.return_center_link} target="blank">SELL</a> */}
                </div>
              </div>
            </div>
          </div>

          <div className={`z-50 mt-[4.1rem] h-full bg-white overflow-y-auto fixed w-full top-0 duration-500 ${userSideOpen ? "left-0" : "left-[100%]"}`}>
            <div className="pages">
              {userSideBack && (<button id="back" className="flex p-3 items-center w-full" onClick={onChangeUserSideOpen}>
                <ChevronLeftIcon className="h-5 w-5 inline-flex" />
                <p>{t('geral.back')}</p>
              </button>)}
              <div className="w-full flex flex-col pb-4 px-3">
                <Link to={"/user"} className={"p-3 no-underline " + (window.location.pathname === "/user" ? "font-bold" : "")} onClick={onChangeSideOpen}>Details</Link>
                <Link to={"/user/wallet"} className={"p-3 no-underline " + (window.location.pathname === "/user/wallet" ? "font-bold" : "")} onClick={onChangeSideOpen}>Wallet</Link>
                <Link to={"/user/listings"} className={"p-3 no-underline " + (window.location.pathname === "/user/listings" ? "font-bold" : "")} onClick={onChangeSideOpen}>Listings</Link>
                <Link to={"/user/offers"} className={"p-3 group flex py-3 no-underline gap-2 items-center " + (window.location.pathname === "/user/offers" ? "font-bold" : "")} onClick={onChangeSideOpen}><div>Offers</div>
                  {userNotifications > 0 && <div className="inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[var(--bg-cart-wishlist-count)] border-2 border-white rounded-full ">{userNotifications}</div>}</Link>
                <Link to={"/user/orders"} className={"p-3 no-underline " + (window.location.pathname === "/user/orders" ? "font-bold" : "")} onClick={onChangeSideOpen}>Orders</Link>
              </div>
            </div>
          </div>
        </div>

        {/* search bar */}
        {openSearchSideBar && <>
          <div role="dialog" className={'fixed w-full h-full flex flex-row md:flex-col-reverse justify-between z-50 ease-in-out duration-300 gap-0 ' + (openSearchSideBar ? 'modal-open-mobile modal-search-open ' : 'modal-search-close ') + (process.env.REACT_APP_SEARCH_OP === "static" ? 'top-0 md:top-[var(--header-height)]' : 'top-0')}>
            <div className="w-[calc(100%_-_450px)] h-full md:w-full flex bg-black bg-opacity-50" onClick={closeSearchSideBarModal}></div>
            <div className={'search-container w-[450px] md:w-full h-auto bg-white flex flex-col h-auto'}>
              <div className={'w-full flex justify-between items-center bg-white gap-3 py-6 px-4 sm:px-6 lg:px-8 ' + (process.env.REACT_APP_SEARCH_OP === "static" ? 'md:hidden' : '')}>
                {process.env.REACT_APP_SEARCH_OP === "overlay" &&
                  <div className="hidden md:flex w-full">
                    <div className="w-1/4 flex items-center">
                      <Link className="header_logo p-2 w-auto" to={"/"}>
                        <img src={process.env.REACT_APP_2HDICON} width="120px" height="25px" alt="logo" />
                      </Link>
                    </div>
                    <div className="header_search relative w-2/4 flex items-center font-normal">
                      <input autoFocus maxLength={50} id="SearchMobile" type="search" placeholder={process.env.REACT_APP_THEME == 'theme3' ? t('header.search') : t('header.search_prelove_item')} className="searchbar_form on_focus bg-transparent w-full outline-none pr-6"
                        value={querySearch}
                        onChange={event => setQuerySearch(event.target.value)} />
                      <button className="absolute right-0 m-2"><MagnifyingGlassIcon className="w-5 h-5"></MagnifyingGlassIcon></button>
                    </div>
                    <div className="w-1/4 flex items-center justify-end">
                      <div className="flex justify-end w-auto">
                        <div className="flex">
                          <button id="customer" title={t('header.my_account')} className="relative p-1 header-icon"
                            onClick={() => onUserClick()}>
                            <span className="sr-only">{t('header.my_account')}</span>
                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                            {tokenUser && userNotifications > 0 && <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[var(--bg-cart-wishlist-count)] border-2 border-white rounded-full -top-2 -right-2 transition duration-500 ease-in-out transform">{userNotifications}</div>}
                          </button>
                          <button id="cart" title={t('header.my_bag')} type="button" className="relative p-1 header-icon" onClick={() => openCart()} >
                            <span className="sr-only">{t('header.my_bag')}</span>
                            <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                            <div className={"absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-[var(--bg-cart-wishlist-count)] border-2 border-white rounded-full -top-2 -right-2 transition duration-500 ease-in-out transform " + (itemAddedToCart ? "-translate-y-1 scale-110" : "")}>{cartQuantity}</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="w-full flex flex-col md:hidden gap-3">
                  <div className="flex justify-between w-full">
                    <div>{t('header.search_our_store')}</div>
                    <button id="close_sidebar_search" title={t('geral.close')} onClick={closeSearchSideBarModal} ><XMarkIcon className='h-5 w-5 flex-shrink-0'></XMarkIcon></button>
                  </div>
                  <div className="header_search relative w-full flex items-center font-normal">
                    <input maxLength={50} id="SearchMobile" type="search" placeholder={process.env.REACT_APP_THEME == 'theme3' ? t('header.search') : t('header.search_prelove_item')} className="searchbar_form on_focus bg-transparent w-full outline-none pr-6"
                      value={querySearch}
                      onChange={event => setQuerySearch(event.target.value)} />
                    <button className="absolute right-0 m-2"><MagnifyingGlassIcon className="w-5 h-5"></MagnifyingGlassIcon></button>
                  </div>
                </div>
              </div>
              <div className={"search-results h-full md:h-auto bg-white p-4 sm:px-6 lg:px-8 overflow-y-auto " + (querySearch.length > 3 ? "visible" : "hidden")}>
                {searchBarList.length === 0 && !searchLoading && <div className="w-full h-hull">Sorry, we can't find any results for '<span className="text-[var(--primary-color)]">{querySearch}</span>'</div>}
                {searchBarList.length > 0 &&
                  <div className="flex flex-col gap-3">
                    <div className="products-list w-full h-auto grid gap-x-4 grid-cols-2 md:grid-cols-5 lg:grid-cols-6 auto-rows-[0] grid-rows-2 md:grid-rows-1 overflow-hidden">
                      {searchBarList.map((product: any, index) => (
                        <Link key={index} className="mt-3 md:mt-0 flex flex-col justify-between no-underline" to={'/product/' + product.product_id + '?variant=' + product.product_variant_id} onClick={closeSearchSideBarModal}>
                          <div className="relative h-full w-full flex">
                            <LazyLoadImage
                              src={product.photo}
                              alt={product.title}
                              width='100%'
                              height='100%'
                              loading="lazy"
                              placeholderSrc={logo}
                              className="absolute inset-0 h-full w-full object-contain object-center group-hover:opacity-75"
                            />
                            <div className='absolute flex flex-wrap gap-2 top-2 left-2'>
                              <div className='block-condition py-1 px-2'>
                                <div className='text-xs uppercase'>{filters?.condition.values.find(condition => condition.id = product?.condition_id)?.option}</div>
                              </div>
                              {product?.Size?.length > 0 && <div className='block-size py-1 px-2'>
                                <div className='text-xs uppercase'>{product?.Size[0]?.O2Child}</div>
                              </div>}
                            </div>
                          </div>
                          <div className='list-product-details mt-4 flex flex-col'>
                            <div className='flex justify-between gap-3 overflow-hidden'>
                              <div className="subtitle2 text-sm truncate">{product.title}</div>
                              {/* <button className={(product.wishlisted ? 'heart' : '')}><HeartIcon className={'h-5 w-5 flex-shrink-0 hover:fill-black ' + (product.wishlisted ? "fill-red-500 stroke-red-500 hover:fill-red-500" : "stroke-black")} title={product.wishlisted ? "Remove from wishlist" : "Add to wishlist"} onClick={(e) => addToWishlist(e, product)}></HeartIcon></button> */}
                            </div>
                            <div className="colors flex mt-1 text-sm">{product?.option1array?.length} {product?.option1array?.length > 1 ? <span>&nbsp;{t('shopping.colors_available')}</span> : <span>&nbsp;{t('shopping.color_available')}</span>}</div>
                            <div className="price mt-1 text-lg font-medium">
                              <div className="flex">{product.min_price}€{product.min_price !== product.max_price && product.max_price && <div>-{product.max_price}€</div>}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                    <div className="flex w-full justify-center items-center">
                      <Link to={"/search?q=" + querySearch} onClick={closeSearchSideBarModal} className="w-auto primary-button uppercase">View more</Link>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </>}

        {
          confirmCheckoutModal && <div className='modal-open fixed w-full h-screen flex items-center justify-center top-0 z-50 bg-black bg-opacity-50'>
            <div className='absolute w-full h-full z-5' onClick={() => closeConfirmModal()}></div>
            <div className='modal-leaveCheckout h-auto bg-white flex flex-col z-10'>
              <div className='px-5 flex justify-between items-start py-4 bg-white'>
                <div className='font-medium text-xl'>{t('logo.confirm_logo_title')}</div>
                <button id="close_dialog" title={t('geral.close')}><XMarkIcon className='h-5 w-5 flex-shrink-0' onClick={() => closeConfirmModal()} ></XMarkIcon></button>
              </div>
              <div className='px-5 py-4 flex flex-col w-full overflow-y-auto'>
                <div className='flex w-full text-sm'>
                  {t('logo.confirm_logo_text')}
                </div>
                <div className='w-full flex items-center py-4 gap-2'>
                  <Link className='w-full secundary-button font-medium text-center' to={"/"} onClick={() => closeConfirmModal()}> {t('logo.leave')} </Link>
                  <button className='w-full primary-button font-medium ' onClick={() => closeConfirmModal()} > {t('logo.cancel')} </button>
                </div>
              </div>
            </div>
          </div>
        }
      </header>
    </div >
  );
}
export default Header;