import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

import swycoLogo from '../../assets/swyco-logo.png'
import { useGeral } from '../../context/GeralContext';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { useTranslation } from 'react-i18next';

function Footer(props) {
  const { t } = useTranslation()
  const { errorToast } = useShoppingCart()
  const { isValidEmail, tokenUser } = useGeral()
  const [emailNewsletter, setEmailNewsletter] = useState<string>("")
  const [submittedEmailNewsletter, setSubmittedEmailNewsletter] = useState<boolean>(false)
  const [submittedSucessEmailNewsletter, setSubmittedSucessEmailNewsletter] = useState<boolean>(false)

  useEffect(() => {
    props.brandDetails?.link_socials.map((link, index) => {
      var div = document.getElementById('link-' + index)
      if (div) {
        div.innerHTML = link.icon
      }
    })
  }, [props])

  function subscribeNewsletter() {
    setSubmittedEmailNewsletter(true)
    setSubmittedSucessEmailNewsletter(false)
    if (emailNewsletter && isValidEmail(emailNewsletter)) {
      var myHeaders = new Headers();
      if (tokenUser) {
        myHeaders.append("Authorization", "Bearer " + tokenUser);
      }
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: emailNewsletter,
        id_brand: Number(process.env.REACT_APP_ID_BRAND)
      })
      var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_URL + '/commons/newsletteremail', requestOptions)
        .then(async response => {
          if (response.ok) {
            return response.json();
          } else {
            var error = await response.text();
            return Promise.reject({ status: response.status, message: error });
          }
        })
        .then(result => {
          setSubmittedSucessEmailNewsletter(true)
          setSubmittedEmailNewsletter(false)
          setEmailNewsletter('')
          setTimeout(() => {
            setSubmittedSucessEmailNewsletter(false)
          }, 5000)
        })
        .catch(error => {
          errorToast(error)
        });
    }
  }
  function addActiveClass(e) {
    if (e.target.classList.contains('active')) {
      e.target.classList.remove('active');
      e.target.classList.add('inactive');
    } else {
      e.target.classList.add('active');
      e.target.classList.remove('inactive');
    }
  }
  function addActiveClassParent(e) {
    if (e.currentTarget.parentElement.classList.contains('active')) {
      e.currentTarget.parentElement.classList.remove('active');
      e.currentTarget.parentElement.classList.add('inactive');
    } else {
      e.currentTarget.parentElement.classList.add('active');
      e.currentTarget.parentElement.classList.remove('inactive');
    }
  }

  return (
    <footer className="footer flex flex-col justify-end w-full divide-y divide-gray-200 mt-4">
      <div></div>
      <div className='footer_wrapper flex flex-col px-4 sm:px-6 lg:px-8 pb-2'>
        <div className="footer_container justify-between items-center sm:p-3 !pt-6 gap-3">
          <div className='home_newsletter flex justify-center items-center w-full py-10 px-0 sm:px-6 lg:px-8'>
            <div className="w-full max-w-[500px] flex flex-col gap-3 justify-center items-center text-center">
              <div className={'newsletter_title text-sm uppercase font-medium ' + (process.env.REACT_APP_ID_BRAND == "5" ? 'hidden' : 'flex')}>{t('newsletter.title')}</div>
              {!submittedSucessEmailNewsletter ? <>
                <div className='newsletter_container text-sm uppercase'>{t('newsletter.content' + process.env.REACT_APP_ID_BRAND)} {process.env.REACT_APP_ID_BRAND == "5" && <a href="https://isto.pt/" target='_blank'>ISTO.PT</a>}</div>
                {process.env.REACT_APP_ID_BRAND == "5" ?
                  <div className="newsletter_input klaviyo-form-WrmSDQ"></div>
                  :
                  <div className='newsletter_input flex flex-col items-start w-full'>
                    <div className="newsletter_form w-full flex">
                      <div className="w-full relative flex flex-col items-start">
                        <input maxLength={50} autoComplete="true" className={"peer relative z-20 h-full bg-transparent rounded-none border w-full py-3 px-3 focus:border-[var(--primary-color)] focus:outline-none " + (submittedEmailNewsletter && (!emailNewsletter || !isValidEmail(emailNewsletter)) ? 'border-red-600 focus:border-red-600' : 'border-gray-200')} id="email-newsletter" type="email" value={emailNewsletter}
                          onChange={(e) => setEmailNewsletter(e.target.value)} onKeyDown={(e) => { if (e.key === "Enter") { subscribeNewsletter() } }}></input>
                        {!emailNewsletter && <label htmlFor="email" className="absolute z-10 top-0 flex items-center py-3 px-3 text-gray-400 transition duration-100 h-full peer-focus:h-auto peer-focus:z-30 peer-focus:-translate-y-2 peer-focus:translate-x-2 peer-focus:transition-all peer-focus:text-xs peer-focus:bg-white peer-focus:py-0 peer-focus:px-1 peer-focus:text-[var(--primary-color)]">{t('geral.form.email_address')}</label>}
                        {emailNewsletter && <label htmlFor="email" className="absolute top-0 flex items-center bg-white z-30 translate-x-2 -translate-y-2 text-gray-400 text-xs peer-focus:text-[var(--primary-color)] py-0 px-1">{t('geral.form.email_address')}</label>}
                      </div>
                      <button className='newsletter-button flex uppercase items-center primary-button px-4 min-h-full' onClick={subscribeNewsletter}><div className='newsletter-text hidden'>{t('newsletter.subscribe')}</div><ChevronRightIcon className='newsletter-icon h-6 w-6 flex-shrink-0' /></button>
                    </div>
                    {submittedEmailNewsletter && !emailNewsletter && <span className='text-xs text-red-600'>{t('geral.form.no_email')}</span>}
                    {submittedEmailNewsletter && emailNewsletter && !isValidEmail(emailNewsletter) && <span className='text-xs text-red-600'>{t('geral.form.invalid_email')}</span>}
                  </div>
                }
              </>
                :
                <div className='flex items-center justify-center gap-2 py-6'>
                  <CheckCircleIcon className='w-5 h-5'></CheckCircleIcon>
                  <div>{t('newsletter.successfully')}</div>
                </div>
              }
            </div>
          </div>
          <div className='relative footer_socialmedia h-auto w-full flex flex-col lg:flex-row items-center'>
            <div className='relative footer_title cursor-pointer w-full group inactive hidden flex flex-row items-center justify-center font-medium' onClick={addActiveClass}>{t('newsletter.socialmedia' + process.env.REACT_APP_ID_BRAND)}
              <ChevronDownIcon className='title_icon absolute right-0 group-[.inactive]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronDownIcon>
              <ChevronUpIcon className='title_icon absolute right-0 group-[.active]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronUpIcon>
            </div>
            <div className='socialmedia_links relative w-auto block md:flex lg:flex-row'>
              {props.brandDetails?.link_socials.map((social_link, index) => (
                <a aria-label={"social-media-" + index} key={index} href={social_link.link} target="_blank" rel="noreferrer"
                  className='relative p-2 flex justify-center items-center gap-1 no-underline'>
                  <div className='icon-socialmedia' id={"link-" + index}></div>
                  <div className='text-socialmedia hidden'>{social_link.name}</div>
                </a>
              ))}
            </div>
          </div>
          <div className='footer_logo w-full flex justify-center py-5 lg:p-0 no-underline'>
            <a aria-label="store-icon" href={props.brandDetails?.main_domain} target="_blank" rel="noreferrer">
              <img src={process.env.REACT_APP_ICON} alt="logo" width="100px" height="33px" className="cursor-pointer" />
            </a>
          </div>
          <div className='relative footer_pages_about w-full h-auto flex flex-col items-center'>
            <div className='footer_title cursor-pointer w-full group inactive hidden flex flex-row items-center justify-center font-medium' onClick={addActiveClass}>About
              <ChevronDownIcon className='title_icon absolute right-0 group-[.inactive]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronDownIcon>
              <ChevronUpIcon className='title_icon absolute right-0 group-[.active]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronUpIcon>
            </div>
            <ul className="relative w-auto block lg:flex lg:flex-row justify-end items-center">
              <li key="about" className="relative flex justify-center items-center p-2">
                <Link to={"/about"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                  {t('pages.about')}
                </Link>
              </li>
              {process.env.REACT_APP_ID_BRAND == "5" && <>
                <li key="about" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/factourism-day" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    FACTOURISM
                  </a>
                </li>
                <li key="price_breakdown" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/price-breakdown-of-the-year" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    PRICE BREAKDOWN
                  </a>
                </li>
                <li key="our_stores" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/our-stores" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    OUR STORES
                  </a>
                </li>
                <li key="care_guide" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/care-guide" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    CARE GUIDE
                  </a>
                </li>
                <li key="our_fabrics" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/our-fabrics" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    OUR FABRICS
                  </a>
                </li>
                <li key="out_factories" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/our-factories" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    OUR FACTORIES
                  </a>
                </li>
                <li key="jobs" className="relative flex justify-center items-center p-2">
                  <a href="https://isto.pt/pages/jobs" target='_blank' className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    JOBS
                  </a>
                </li>
              </>}
            </ul>
          </div>
          <div className='relative footer_pages_help w-full h-auto flex flex-col items-center lg:items-end'>
            <div className='footer_title cursor-pointer w-full group inactive hidden flex flex-row items-center justify-center font-medium' onClick={addActiveClass}>Help
              <ChevronDownIcon className='title_icon absolute right-0 group-[.inactive]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronDownIcon>
              <ChevronUpIcon className='title_icon absolute right-0 group-[.active]:flex hidden text-[var(--primary-color)] w-7 h-7' onClick={addActiveClassParent}></ChevronUpIcon>
            </div>
            <ul className="relative w-auto block lg:flex lg:flex-row justify-end items-center">
              <li key="faqs" className="relative flex justify-center items-center p-2">
                <Link to={"/faqs"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                  {t('pages.faqs')}
                </Link>
              </li>
              <li key="shipping" className="relative flex justify-center items-center p-2">
                <Link to={"/shipping"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                  {t('pages.shipping_delivery')}
                </Link>
              </li>
              {process.env.REACT_APP_ID_BRAND == "5" && <li key="livroreclamaoes" className="relative flex justify-center items-center p-2">
                <a href={"https://www.livroreclamacoes.pt/Inicio/"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                  Livro de reclamações
                </a>
              </li>}
              {process.env.REACT_APP_ID_BRAND != "5" && <>
                <li key="terms-and-conditions" className="relative justify-center flex items-center p-2">
                  <Link to={"/terms-and-conditions"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    {t('pages.terms_conditions')}
                  </Link>
                </li>
                <li key="privacy-policy" className="relative flex justify-center items-center p-2">
                  <Link to={"/privacy-policy"} className="relative flex justify-between no-underline font-medium whitespace-nowrap">
                    {t('pages.privacy_policy')}
                  </Link>
                </li>
              </>}
            </ul>
          </div>
        </div>
        {process.env.REACT_APP_ID_BRAND == "5" &&
          <div className='other_links flex justify-center'>
            <li key="terms-and-conditions" className="relative justify-center flex items-center p-2">
              <Link to={"/terms-and-conditions"} className="relative flex justify-between normal-case no-underline font-medium text-xs whitespace-nowrap">
                {t('pages.terms_conditions')}
              </Link>
            </li>
            <div className="relative flex justify-center items-center p-2">|</div>
            <li key="privacy-policy" className="relative flex justify-center items-center p-2">
              <Link to={"/privacy-policy"} className="relative flex justify-between normal-case no-underline font-medium text-xs whitespace-nowrap">
                {t('pages.privacy_policy')}
              </Link>
            </li>
          </div>}
        <div className="powered-block w-full h-auto flex justify-center items-center md:justify-end text-xs text-gray-400">Powered by&nbsp;<a aria-label='swyco-icon' className="flex" href="https://swyco.io/" target="_blank" rel="noreferrer">
          <img src={swycoLogo} alt="logo" width="60px" height="9px" className="h-auto cursor-pointer" />
        </a></div>
      </div>
    </footer>
  );
}

export default Footer;
