import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        resources: {
            en: {
                translation: {
                    geral: {
                        back: "Back",
                        close: "Close",
                        cancel: "Cancel",
                        save: "Save",
                        search: "Search",
                        next: "Next",
                        no: "No",
                        yes: "Yes",
                        remove: "Remove",
                        no_search: "Please try a different search term or go back to the homepage.",
                        back_home: "Back to home page",
                        expires_in: "Expires in",
                        days: "days",
                        hours_ans: "hours and",
                        minutes: "minutes",
                        no_results_found: "No results found",
                        form: {
                            email_address: "Email address",
                            no_email: "Please enter your email address.",
                            invalid_email: "Invalid email address.",
                            first_name: "First name",
                            no_first_name: "Please enter your first name.",
                            last_name: "Last name",
                            no_last_name: "Please enter your last name.",
                            phone: "Phone number",
                            no_phone: "Please enter your phone number.",
                            iban: "IBAN",
                            no_iban: "Please enter your IBAN.",
                            current_password: "Current password",
                            no_current_password: "Please enter your current password.",
                            new_password: "New password",
                            no_new_password: "Please enter your new password.",
                            confirm_password: "Confirm password",
                            no_confirm_password: "Please enter your confirm password.",
                            password: "Password",
                            no_password: "Please enter your password.",
                            password_not_match: "The password does not match.",
                            incorret_combination: "Incorrect combination of user name and password.",
                            password_rules: "Minimum 8 characters, including lowercase, uppercase, a number and a special character.",
                            name: "Name",
                            no_name: "Please enter your name.",
                            address: "Address",
                            no_address: "Please enter your address.",
                            address2: "Address 2 (optional)",
                            zip_code: "ZIP code",
                            no_zip_code: "Please enter your ZIP code.",
                            city: "City",
                            no_city: "Please enter your city.",
                            country: "Country",
                            no_country: "Please enter your country.",
                            default_address: "Default address",
                            add_address: "Add address",
                            save_address: "Save address",
                            edit_address: "Edit address",
                            no_zip_code_country: "Please enter ZIP code or country."
                        },
                        summary: {
                            summary: "Summary",
                            subtotal: "Subtotal",
                            processing_fee: "Processing fee",
                            verification_fee: "Verification fee",
                            delivery: "Delivery",
                            discount: "Discount",
                            total: "Total"
                        }
                    },
                    categories: {
                        women: "Women",
                        men: "Men",
                        clothing: "Clothing",
                        accessories: "Accessories",
                        shop: "Shop",
                        sell: "Sell",
                        second_hand: "Pre-loved",
                        blosers: "Beautiful losers",
                        all: "All"
                    },
                    pages: {
                        about: "About",
                        faqs: "FAQs",
                        shipping_delivery: "Shipping & delivery",
                        terms_conditions: "Terms & conditions",
                        privacy_policy: "Privacy policy"
                    },
                    newsletter: {
                        title: "Subscribe to our circular newsletter",
                        content0: "Sign up for our emails to stay up to date on the latest products and offers, as long as with exclusive content",
                        content1: "Sign up for our emails to stay up to date on the latest products and offers, as long as with exclusive content",
                        content2: "Sign up for our emails to stay up to date on the latest products and offers, as long as with exclusive content",
                        content4: "Sign up for our emails to stay up to date on the latest products and offers, as long as with exclusive content",
                        content5: "BE THE FIRST TO KNOW ABOUT NEW ARRIVALS, SPECIAL OFFERS, RAMBLINGS ON LIFE AND RECEIVE 10% OFF YOUR FIRST ORDER AT",
                        successfully: "You have successfully subscribed to our newsletter.",
                        subscribe: "Subscribe",
                        socialmedia0: "Follow us",
                        socialmedia1: "Follow us",
                        socialmedia2: "Follow us",
                        socialmedia4: "Follow us",
                        socialmedia5: "Social media"
                    },
                    my_account: {
                        my_account: "My account",
                        sign_out: "Sign out",
                        details: {
                            details: "Details",
                            hello_customer: "Hello, {{customer}}",
                            user_details: "User details",
                            user_password: "Change password",
                            save_changes: "Save changes",
                            change_user_details: "Change user details",
                            security: "Security",
                            transfer_account: "Transfer account",
                            address_book: "Address book",
                            add_address: "Add address",
                            edit_address: "Edit address",
                            save_address: "Save address",
                            wishlist: "Wishlist",
                            no_wishlist: "Your wishlist is currently empty.",
                            add_bag: "Add to cart",
                            view_bag: "View cart",
                            delete_address: "Apagar morada",
                            delete: "Apagar"
                        },
                        wallet: {
                            wallet: "Wallet",
                            my_wallet: "My wallet",
                            total_credit: "Total credit",
                            activity: "Activity",
                            no_activity: "You currently have no activity.",
                            activity_table: {
                                type: "Type",
                                amount: "Amount",
                                date: "Date",
                                selling_listing: "Selling listing",
                                bank_transfer: "Bank transfer to ",
                                discount_code: "Discount code",
                                transaction_fee: "Transaction fee for SWYCO",
                                code: "Code",
                                status: "Status",
                                active: "Active",
                                inactive: "Inactive",
                                copied_to_clipboard: "Copied to clipboard",
                                click_to_copy: "Click to copy",
                                copy_to_clipboard: "Copy to clipboard",
                                discount_code_created: "Discount code created",
                                bank_deposit: "Transfer to bank account",
                                deposit_from_listing: "Deposit from listing",
                                hide_discount: "Hide discount code",
                                view_discount: "View discount code"
                            },
                            get_credit: "Get credit",
                            discount_code_info: "Discount codes generated here are redeemable at {{website}} only. They cannot be used in the second-hand marketplace.",
                            discount_amount: "Discount amount",
                            no_discount_amount: "Please enter amount discount.",
                            invalid_discount_amount: "Please enter an amount greater than {{minprice}}€ and less than {{maxprice}}€.",
                            transfer_account: "Transfer account",
                            transfer_amount: "Transfer amount",
                            no_transfer_amount: "Please enter amount transfer.",
                            invalid_transfer_amount: "Please enter an amount greater than {{minprice}}€ and less than {{maxprice}}€.",
                            generate_discount_code: "Generate discount code",
                            transfer_my_account: "Transfer to my account",
                            transaction_fee: "Transaction fee",
                            total_receive: "You will receive",
                            amount: "Amount",
                            bank_transfer_text: "Due to applicable processing fees and charges, the amount to be transferred to your account will be <1>{{value}}</1>% of the submitted value. To receive 100% of the amount in credit, you may <3>generate a discount code</3>.",
                            no_transfer_account: "You haven't registered a bank transfer account yet.",
                            bank_transfer_explain: "To facilitate this process, we have partnered with SWYCO and utilize the secure services of Stripe Express for seamless bank transfer payments.",
                            register_account: "Register account",
                            payment_options: "Payment options"
                        },
                        listings: {
                            listings: "Listings",
                            my_listings: "My listings",
                            no_listings: "You currently have no listings.",
                            add_listing: "Add listing",
                            sell_item: "Sell an item",
                            listing_number: "Listing number",
                            sold_date: "Sold date",
                            customer: "Customer",
                            listing_date: "Listing date",
                            total: "Total",
                            details: "Details",
                            resend_label_email: "Resend label email",
                            view: "View",
                            active: "Active",
                            archived: "Archived",
                            sold: "Sold",
                            all_listings: "All listings",
                            active_listings: "Active listings",
                            archived_listings: "Archived listings",
                            find_my_item: "Find your {{brand}} item",
                            search_content: "Product name, SKU or EAN",
                            address: "Address",
                            change_address: "Change address",
                            add_address: "Add address",
                            color: "Color",
                            size: "Size",
                            condition: "Condition",
                            comment: "Comment",
                            optional: "optional",
                            upload_photo: "Upload photo",
                            upload_photo_content: "Please upload at least two photos (max. four) of your item — e.g. one of the front and one of the back.",
                            price: "Price",
                            you_receive: "You receive",
                            buyer_pays: "Buyer pays",
                            no_price: "Please first fill in the fields above to set the price for your item.",
                            error_price: "The final price must be greater than  {{minPrice}}€ and less than {{maxPrice}}€.",
                            price_content: "Based on the original retail price of {{originalPrice}} EUR and the condition of your item - {{condition}}, we recommend setting your listing price at {{suggestedPrice}} EUR, ~{{condition_percentage}}% of retail.",
                            submit_2hand: "Submit"
                        },
                        listing_id: {
                            back_listings: "Back to My Listings",
                            listing: "Listing",
                            options: "Options",
                            product: "Product",
                            delivery_status: "Delivery Information",
                            original_price: "Original price",
                            listing_generate: "This listing was generated from order <1>{{order_number}}</1> at <3>{{link}}</3>.",
                            smart_pricing: "Smart pricing",
                            smart_pricing_active: "Automatically adjust your price based on demand. Your price stays within the range you set, and you can change it at any time.",
                            min_price_item: "Minimum price for your item:",
                            min_price_item_extra: "This price is established by nu-in second-hand marketplace",
                            your_min_price: "Your minimum price:",
                            next_update_price: "Next updated price:",
                            next_update_price_extra: "The price will be updated every 7 days",
                            my_address: "My Product's Address",
                            buyer_details: "Buyer details",
                            shipping_details: "Shipping details",
                            resend_label_email: "Resend label email",
                            view: "View",
                            change_minimum_price: "Change minimum price",
                            minimum_price: "Minimum price",
                            no_minimum_price: "Please enter a minimum price.",
                            invalid_minimum_price: "Please enter a valid minimum price.",
                            smart_price_confirmation: "Smart Pricing Confirmation",
                            disabled_smart_price: "Are you sure you want to turn off smart pricing?",
                            next_update: "The next update is on {{time}}.",
                            minimum_price_content: "Starting 7 days after listing your item, we will lower the price of your item by 10% every 7 days until your item sells or gets to <1>minimum price - {{minPriceAux}}€</1>.",
                            active: "Active",
                            inactive: "Inactive",
                            archived: "Archived"
                        },
                        offers: {
                            offers: "Offers",
                            on_my_listings: "On my listings",
                            your_offers: "Your offers",
                            no_offers: "You currently have no offers",
                            listed_by: "Listed by <1>{{customer}}</1> from {{country}}",
                            negotiation_timeout: "The negotiation has timed out.",
                            negotiation_timeout_not_purchase: "The negotiation has timed out. You did not purchase the item in time.",
                            negotiation_reject_by_me: "The negotiation has timed out. You did not accept/reject the item in time.",
                            negotiation_reject_by_seller: "The negotiation has timed out. The seller rejected the offer.",
                            customer_not_buyer: "The negotiation has timed out. The customer did not buy the item in time.",
                            customer_time_to_buy: "The customer has <1></1> to buy this item.",
                            add_bag: "Add cart",
                            view_bag: "View cart",
                            accept: "Accept",
                            decline: "Decline",
                            expired: "Expired",
                            all_offers: "All offers",
                            active_offers: "Active offers",
                            archived_offers: "Archived offers"
                        },
                        orders: {
                            orders: "Orders",
                            order: "Order",
                            my_orders: "My orders",
                            shop_all: "Shop all",
                            no_orders: "You currently have no orders.",
                            order_number: "Order number",
                            order_date: "Order date",
                            total: "Total",
                            details: "Details",
                            close_details: "Close order details",
                            product: "product",
                            shipping_data: "Shiping data",
                            delivery: "Delivery",
                            billing: "Billing",
                            products: "Products",
                            order_complete: "Order complete"
                        },
                        order_id: {
                            back_order: "Back to My Orders",
                            order: "Order",
                            products: "Products",
                            package: "Package",
                            delivery_status: "Delivery Information",
                            status: "Status",
                            shipped_by: "Shipped by",
                            delivery_date: "Delivery date",
                            tracking_number: "Tracking number",
                            track_package: "Track your package",
                            leave_review: "Leave review",
                            review: "Review",
                            save_review: "Save review",
                            delivery_address: "Delivery address",
                            billing_address: "Billing address",
                            payment_information: "Payment information",
                        }
                    },
                    login: {
                        check_inbox: "Check your inbox",
                        forgot_password: "Forgot your password?",
                        sign_in: "Sign in",
                        new_here: "I'm new here",
                        register: "Register",
                        error_register: "Error register",
                        back_to_sign: "Back to sign in",
                        or: "OR",
                        reset_password: "Reset password",
                        link_to_reset_password: "Enter your email address and we'll send you a link to reset your password",
                        create_new_account: "Create a new second-hand account.",
                        register_later: "In a hurry? No problem, you can register later.",
                        continue_guest: "Continue as Guest",
                        sent_email: "We've sent an email to the address provided. Can't find it? Check your junk folder.",
                        account_distinct: "Please be aware that a <1>{{title}}</1> account is distinct and independent from your main account on <3>{{link}}</3>."
                    },
                    bag: {
                        shopping_bag: "Shopping bag",
                        product_unavailable: "Product unavailable, remove from cart",
                        offer_unavailable: "Offer unavailable, remove from cart",
                        total: "Total",
                        taxes_not_included: "Taxes not included",
                        continue_shopping: "Continue shopping",
                        go_to_checkout: "Go to checkout",
                        empty_bag: "There’s nothing in your bag yet...",
                        start_shopping: "Start shopping"
                    },
                    header: {
                        my_account: "My account",
                        my_bag: "My bag",
                        sign_in: "Sign in",
                        sign_out: "Sign out",
                        search_our_store: "Search our store",
                        search_prelove_item: "Searching for a pre-loved item?",
                        search: "Search"
                    },
                    home: {
                        shop: "Shop",
                        sell: "Sell",
                        view_all: "View all",
                        discover_more: "Discover more",
                        shop_second_hand: "Shop 2nd hand",
                        shop_blosers: "Shop beautiful losers"
                    },
                    shopping: {
                        filters: "Filters",
                        filters_category: {
                            condition: "Condition",
                            price: "Price",
                            collection: "Collection",
                            category: "Category",
                            country: "Country"
                        },
                        clear_filters: "Clear filters",
                        view_results: "View results",
                        no_products: "We don't have what you're looking for this time, but why not take a look at our new arrivals.",
                        no_products_preloved: "We don't have pre-loved items to show this time, but why not take a look at our beautiful losers selection.",
                        new_arrivals: "New arrivals",
                        sort_by: "Sort by",
                        sizes: "Sizes",
                        color_available: "color available",
                        colors_available: "colors available",
                        latest_additions: "Latest additions"
                    },
                    product: {
                        seller: "Seller",
                        offer_accepted: "Your offer has been accepted.",
                        buy_offer: "Buy before time runs out!",
                        original_price: "Original price:",
                        size: "Size",
                        color: "Color",
                        alert_sizes: "Alert sizes",
                        size_guide: "Size Guide",
                        condition: "Condition",
                        listed_by: "Listed by",
                        from: "from",
                        buy_for: "Buy for",
                        add_to_bag: "Add to cart",
                        add_wishlist: "Add to wishlist",
                        remove_wishlist: "Remove from wishlist",
                        item_added_to_cart: "Item added to cart",
                        view_bag: "View bag",
                        make_an_offer: "Make an offer",
                        quality_authentication: "Available for quality assurance & authentication",
                        seller_comments: "Seller comments",
                        details: "Details",
                        how_it_works: "How it works",
                        how_it_works_content0: "As a buyer, you will be asked to choose a DHL Parcel or Post pick-up point that is most convenient for you during the checkout process. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a pre-owned product, check our <1>FAQs</1>.",
                        how_it_works_content1: "As a buyer, you will be asked to choose a DHL Parcel or Post pick-up point that is most convenient for you during the checkout process. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a pre-owned product, check our <1>FAQs</1>.",
                        how_it_works_content2: "As a buyer, you will be asked to choose a DHL Parcel or Post pick-up point that is most convenient for you during the checkout process. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a pre-owned product, check our <1>FAQs</1>.",
                        how_it_works_content3: "As a buyer, you will be asked to choose a DHL Parcel or Post pick-up point that is most convenient for you during the checkout process. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a pre-owned product, check our <1>FAQs</1>.",
                        how_it_works_content4: "As a buyer, you will be asked to choose a DHL Parcel or Post pick-up point that is most convenient for you during the checkout process. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a pre-owned product, check our <1>FAQs</1>.",
                        how_it_works_content5: "As a buyer, you will be asked to choose a DPD or Post pick-up point that is most convenient for you during checkout. Once you buy an item, the seller must ship it to you within 5 business days after the sale. If you want to know more about buying a Pre-Owned or Beautiful Losers product, please check our <1>FAQs</1>.",
                        shipping_delivery: "Shipping and delivery",
                        shipping_delivery_content0: "The cost of delivery for second-hand goods is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed to you at the checkout. For more details about it read our <1>FAQs</1>.",
                        shipping_delivery_content1: "The cost of delivery for second-hand goods is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed to you at the checkout. For more details about it read our <1>FAQs</1>.",
                        shipping_delivery_content2: "The cost of delivery for second-hand goods is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed to you at the checkout. For more details about it read our <1>FAQs</1>.",
                        shipping_delivery_content3: "The cost of delivery for second-hand goods is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed to you at the checkout. For more details about it read our <1>FAQs</1>.",
                        shipping_delivery_content4: "The cost of delivery for second-hand goods is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed to you at the checkout. For more details about it read our <1>FAQs</1>.",
                        shipping_delivery_content5: "The delivery cost is based primarily on the origin and destination of your item. The fees and delivery times will be calculated automatically and informed at checkout. For more information about Shipping & Delivery, please read our <1>FAQs</1>.",
                        transparency: "Transparency",
                        transparency_content: "Cotton clear transparency. When you choose ISTO., what you see is exactly what you get. That means you have a clear understanding of everything that went into making our clothes. First, we only work with factories that can be held to the higher standards in social responsibility and work policies. Second, you get to see exactly how much we paid for each and every component from materials to production.",
                        new_arrivals: "New arrivals",
                        alert_sizes_modal: {
                            alert_me_available: "Alert me when available",
                            select_your_sizes: "Select your desired size(s)",
                            notify_sizes: "We will promptly notify you via email once the item is submitted in your preferred size.",
                            alert_me: "Alert me"
                        },
                        offer_modal: {
                            make_an_offer: "Make an offer",
                            submit_purchase_proposal: "Submit a purchase proposal to the seller for the desired item.",
                            offer_price: "Offer your price",
                            no_offer_price: "Please enter your price offer",
                            invalid_offer_price: "Please enter a price between {{min_offer}}{{currency}} and {{max_offer}}{{currency}}"
                        },
                        listed_by_from: "Listed by <1>{{customer}}</1> from {{country}}"
                    },
                    vendor: {
                        shop_all: "Shop all",
                        items_for_sale: "'s items for sale",
                        no_products: "does not have any items available for sale."
                    },
                    new_arrivals: {
                        sizes: "Sizes",
                        color_available: "color available",
                        colors_available: "colors available"
                    },
                    checkout: {
                        tabs: {
                            delivery: "Delivery",
                            review: "Review",
                            payment: "Payment"
                        },
                        view_summary: "View summary",
                        empty_bag: "Your shopping bag is currently empty!",
                        start_shopping: "Start shopping",
                        delivery_details: "Delivery details",
                        change_address: "Change address",
                        add_address: "Add address",
                        edit_address: "Edit",
                        no_address: "You haven’t added an address yet. Please <1>enter a full address</1>.",
                        no_delivery_address: "Please enter your delivery address.",
                        same_delivery_billing: "The billing address is the same as the delivery address",
                        billing_details: "Billing details",
                        your_delivery_options: "Your delivery options",
                        review_order: "Review order",
                        choose_another_address: "Choose another address",
                        address_list: "Address list",
                        new_address: "New address",
                        delivery_option_2: "Prefer to receive your item right away? Select this option for direct delivery to your doorstep or a convenient pickup point. Your item will be shipped directly from the seller, providing a quick and hassle-free shopping experience.",
                        delivery_option_X: "When you choose this option, our experts will carefully inspect and authenticate your garment for quality and authenticity. This ensures that you receive a verified, top-quality item that meets our rigorous standards. Your purchase is backed by our guarantee, giving you peace of mind."
                    },
                    review: {
                        delivery_address: "Delivery address",
                        billing_address: "Billing address",
                        delivery_billing_address: "Delivery and billing address",
                        your_delivery_options: "Your delivery options",
                        sending_from: "Sending from",
                        listed_by: "Listed by",
                        confirm_order: "Confirm order",
                        apply: "Apply",
                        promo_code: "Promo code",
                        no_promo_code: "Please enter a valid discount code.",
                        invalid_promo_code: "Discount code is not valid.",
                        shipping_fee: "Shipping fee",
                        processing_fee: "Processing fee",
                        choose_delivery: "Delivery address",
                        pickup_store: "Pickup in a store",
                        pickup_point: "Pickup point",
                        choose_delivery_option: "Please choose one delivery option.",
                        proceed_payment: "Proceed to payment",
                        pay_now: "Pay now",
                        loading: "Loading",
                        change_pickup: "Change pickup address",
                        pickup_points: "Pickup points",
                        map: "Map",
                        choose_pickup_point: "Choose your pickup point",
                        points_near_me: "Find pickup points near me"
                    },
                    logo: {
                        confirm_logo_title: "Are you sure you want to leave the checkout?",
                        confirm_logo_text: "If you leave the checkout now, you will lose all the progress you have made so far.",
                        leave: "Leave",
                        cancel: "Cancel"
                    }
                }
            },
            pt: {
                translation: {
                    geral: {
                        back: "Voltar",
                        close: "Fechar",
                        cancel: "Cancelar",
                        save: "Guardar",
                        search: "Pesquisar",
                        next: "Seguinte",
                        no: "Não",
                        yes: "Sim",
                        remove: "Remover",
                        no_search: "Tente uma pesquisa diferente ou volte para a página inicial.",
                        back_home: "Voltar à página inicial",
                        expires_in: "Expira em",
                        days: "dias",
                        hours_ans: "horas e",
                        minutes: "minutos",
                        no_results_found: "Nenhum resultado encontrado",
                        form: {
                            email_address: "Endereço de email",
                            no_email: "Insira o seu endereço de email.",
                            invalid_email: "Endereço de email inválido.",
                            first_name: "Primeiro nome",
                            no_first_name: "Insira o seu primeiro nome.",
                            last_name: "Último nome",
                            no_last_name: "Insira o seu último nome.",
                            phone: "Telemóvel",
                            no_phone: "Insira o seu número de telemóvel.",
                            iban: "IBAN",
                            no_iban: "Insira o seu IBAN.",
                            current_password: "Senha atual",
                            no_current_password: "Insira a sua senha atual.",
                            new_password: "Nova senha",
                            no_new_password: "Insira a sua nova senha.",
                            confirm_password: "Confirmar senha",
                            no_confirm_password: "Insira novamente a sua senha.",
                            password: "Senha",
                            no_password: "Insira a sua senha.",
                            password_not_match: "As senhas não correspondem.",
                            incorret_combination: "Combinação incorreta de nome e senha.",
                            password_rules: "Mínimo de 8 caracteres, incluindo letras minúsculas, maiúsculas, um número e um caractere especial.",
                            name: "Nome",
                            no_name: "Insira o seu nome.",
                            address: "Morada",
                            no_address: "Insira a sua morada.",
                            address2: "Morada 2 (optional)",
                            zip_code: "Código postal",
                            no_zip_code: "Insira o seu código postal.",
                            city: "Cidade",
                            no_city: "Insira a sua cidade.",
                            country: "País",
                            no_country: "Insira o seu país.",
                            default_address: "Morada default",
                            add_address: "Adicionar morada",
                            save_address: "Guardar morada",
                            edit_address: "Editar morada",
                            no_zip_code_country: "Insira um código postal ou país."
                        },
                        summary: {
                            summary: "Sumário",
                            subtotal: "Subtotal",
                            processing_fee: "Taxa de processamento",
                            verification_fee: "Taxa de verificação",
                            delivery: "Entrega",
                            discount: "Desconto",
                            total: "Total"
                        }
                    },
                    categories: {
                        women: "Mulher",
                        men: "Homem",
                        clothing: "Roupa",
                        accessories: "Acessórios",
                        second_hand: "Pre-loved",
                        blosers: "Beautiful losers",
                        all: "Tudo"
                    },
                    pages: {
                        about: "Sobre",
                        faqs: "Perguntas frequentes",
                        shipping_delivery: "Envio e Entrega",
                        terms_conditions: "Termos e Condições",
                        privacy_policy: "Política de Privacidade",
                    },
                    newsletter: {
                        title: "Subscreva a nossa newsletter",
                        content0: "Subscreva para receber os nossos e-mails e manter-se atualizado, com conteúdo exclusivo, sobre os últimos produtos e ofertas.",
                        content1: "Subscreva para receber os nossos e-mails e manter-se atualizado, com conteúdo exclusivo, sobre os últimos produtos e ofertas.",
                        content2: "Subscreva para receber os nossos e-mails e manter-se atualizado, com conteúdo exclusivo, sobre os últimos produtos e ofertas.",
                        content4: "Subscreva para receber os nossos e-mails e manter-se atualizado, com conteúdo exclusivo, sobre os últimos produtos e ofertas.",
                        content5: "SEJA O PRIMEIRO A SABER DAS NOVIDADES, OFERTAS ESPECIAIS, DIVULGAÇÕES SOBRE A VIDA E RECEBA 10% DE DESCONTO NA SUA PRIMEIRA ENCOMENDA EM",
                        successfully: "Subscreveu com sucesso a nossa newsletter.",
                        subscribe: "Subscreva",
                        socialmedia0: "Siga-nos",
                        socialmedia1: "Siga-nos",
                        socialmedia2: "Siga-nos",
                        socialmedia4: "Siga-nos",
                        socialmedia5: "Redes sociais"
                    },
                    my_account: {
                        my_account: "Minha conta",
                        sign_out: "Terminar sessão",
                        details: {
                            details: "Detalhes",
                            hello_customer: "Olá, {{customer}}",
                            user_details: "Detalhes do utilizador",
                            user_password: "Alterar senha",
                            save_changes: "Guardar alterações",
                            change_user_details: "Alterar os meus dados",
                            security: "Segurança",
                            transfer_account: "Opções de transferência",
                            address_book: "As minhas moradas",
                            add_address: "Adicionar morada",
                            edit_address: "Editar morada",
                            save_address: "Guardar morada",
                            wishlist: "Favoritos",
                            no_wishlist: "A sua lista de favoritos está vazia de momento.",
                            add_bag: "Adicionar ao carrinho",
                            view_bag: "Ver carrinho",
                            delete_address: "Apagar morada",
                            delete: "Apagar"
                        },
                        wallet: {
                            wallet: "Carteira",
                            my_wallet: "Minha carteira",
                            total_credit: "Crédito total",
                            activity: "Atividade",
                            no_activity: "Ainda não tem nenhuma atividade registada.",
                            activity_table: {
                                type: "Tipo",
                                amount: "Valor",
                                date: "Data",
                                bank_transfer: "Transferência para a conta",
                                discount_code: "Código de desconto",
                                selling_listing: "Venda da listing",
                                transaction_fee: "Taxa de transação para a SWYCO",
                                code: "Código",
                                status: "Estado",
                                active: "Ativo",
                                inactive: "Inativo",
                                copied_to_clipboard: "Copiado para a área de transferência",
                                click_to_copy: "Clique para copiar",
                                copy_to_clipboard: "Copiar para a área de transferência",
                                discount_code_created: "Código de desconto criado",
                                bank_deposit: "Transferência para a conta bancária",
                                deposit_from_listing: "Depósito da listing",
                                hide_discount: "Esconder código de desconto",
                                view_discount: "Mostrar código de desconto"
                            },
                            get_credit: "Obter crédito",
                            discount_code_info: "Os códigos de desconto gerados aqui podem ser aplicados apenas em {{website}}. Eles não podem ser usados ​​no nosso marketplace.",
                            discount_amount: "Valor do desconto",
                            no_discount_amount: "Insira o valor do código de desconto.",
                            invalid_discount_amount: "Insira um valor válido.",
                            transfer_account: "Conta a transferir",
                            transfer_amount: "Valor a transferir",
                            no_transfer_amount: "Insira o valor a transferir.",
                            invalid_transfer_amount: "Insira um valor válido.",
                            generate_discount_code: "Gerar código de desconto",
                            transfer_my_account: "Transferir para a minha conta",
                            transaction_fee: "Taxa de transação",
                            total_receive: "Total a receber",
                            amount: "Valor",
                            bank_transfer_text: "Devido às taxas e encargos da transferência bancária, o valor a ser transferido para sua conta será <1>{{value}}</1>% do valor enviado. Para receber 100% do valor em crédito, você pode <3>gerar um código de desconto</3>.",
                            no_transfer_account: "You haven't registered a bank transfer account yet.",
                            bank_transfer_explain: "To facilitate this process, we have partnered with SWYCO and utilize the secure services of Stripe Express for seamless bank transfer payments.",
                            register_account: "Register account",
                            payment_options: "Opções de pagamento"
                        },
                        listings: {
                            listings: "Artigos",
                            my_listings: "Os meus artigos",
                            no_listings: "Neste momento não tem nenhum artigo.",
                            add_listing: "Adicionar um artigo",
                            sell_item: "Vender um artigo",
                            listing_number: "Artigo nº",
                            sold_date: "Vendido a",
                            customer: "Customer",
                            listing_date: "Publicado a",
                            total: "Total",
                            details: "Detalhes",
                            resend_label_email: "Reenviar label para o e-mail",
                            view: "Ver",
                            active: "Activo",
                            archived: "Arquivado",
                            sold: "Vendido",
                            all_listings: "Todos os artigos",
                            active_listings: "Artigos ativos",
                            archived_listings: "Artigos arquivados",
                            find_my_item: "Encontre o seu artigo {{brand}}",
                            search_content: "Nome do produto, SKU ou EAN",
                            address: "Morada",
                            change_address: "Alterar morada",
                            add_address: "Adicionar morada",
                            color: "Cor",
                            size: "Tamanho",
                            condition: "Condição",
                            comment: "Comentário",
                            optional: "opcional",
                            upload_photo: "Carregar fotos",
                            upload_photo_content: "Carregue pelo menos duas fotos (no máximo quatro) do seu artigo – por exemplo, uma da frente e outra de trás.",
                            price: "Preço",
                            you_receive: "Você recebe",
                            buyer_pays: "O cliente paga",
                            no_price: "Por favor, primeiro preencha os campos acima para definir o preço do seu artigo.",
                            error_price: "O preço final deverá ser superior a {{minPrice}}€ e inferior a {{maxPrice}}€.",
                            price_content: "Com base no preço original do seu artigo {{originalPrice}}€ e a condição do mesmo - {{condition}}, sugerimos {{suggestedPrice}}€ para o preço do seu artigo, ~{{condition_percentage}}% do preço original.",
                            submit_2hand: "Submeter"
                        },
                        listing_id: {
                            back_listings: "Voltar",
                            listing: "Artigo",
                            options: "Opções",
                            product: "Produto",
                            original_price: "Preço original",
                            delivery_status: "Informações do envio",
                            listing_generate: "Este listing foi gerado a partir do pedido <1>{{order_number}}</1> do link <3>{{link}}</3>.",
                            smart_pricing: "Preço inteligente",
                            smart_pricing_active: "O preço do seu produto é ajustado automaticamente a cada 7 dias. O preço permanece dentro do intervalo definido e pode alterá-lo a qualquer momento.",
                            min_price_item: "Preço mínimo para o seu produto:",
                            min_price_item_extra: "Este preço é estabelecido pelo circular center de segunda mão",
                            your_min_price: "O seu preço mínio:",
                            next_update_price: "Próxima atualização do preço:",
                            next_update_price_extra: "O preço é atualizado a cada 7 dias",
                            my_address: "Morada dos meus produtos",
                            buyer_details: "Detalhes do comprador",
                            shipping_details: "Detalhes do envio",
                            resend_label_email: "Reenviar label para o email",
                            view: "Ver",
                            change_minimum_price: "Alterar preço mínimo",
                            minimum_price: "Preço mínimo",
                            no_minimum_price: "Insira o preço mínimo do item.",
                            invalid_minimum_price: "Insira um valor válido para o preço mínimo.",
                            smart_price_confirmation: "Confirmação do preço inteligente",
                            disabled_smart_price: "Tem certeza que pretende desativar o preço inteligente?",
                            next_update: "A próxima atualização do preço é no dia {{time}}.",
                            minimum_price_content: "Após 7 dias da listagem do seu item, reduziremos o preço dele em 10% a cada 7 dias até que o seu item seja vendido ou atinja o <1>preço mínimo - {{minPriceAux}}€</1>.",
                            active: "Activo",
                            inactive: "Inativo",
                            archived: "Arquivado"
                        },
                        offers: {
                            offers: "Ofertas",
                            on_my_listings: "Nos meus produtos",
                            your_offers: "As minhas ofertas",
                            no_offers: "Ainda não tem ofertas.",
                            listed_by: "Vendido por <1>{{customer}}</1> de {{country}}",
                            negotiation_timeout: "A negociação expirou.",
                            negotiation_timeout_not_purchase: "A negociação expirou. Você não comprou o item a tempo.",
                            negotiation_reject_by_me: "A negociação expirou. Você não aceitou/rejeitou o item a tempo.",
                            negotiation_reject_by_seller: "A negociação expirou. O vendedor rejeitou a oferta.",
                            customer_not_buyer: "A negociação expirou. O cliente não comprou o item a tempo.",
                            customer_time_to_buy: "O utilizador <1></1> comprou este item.",
                            add_bag: "Adicionar ao carrinho",
                            view_bag: "Ver carrinho",
                            accept: "Aceitar",
                            decline: "Rejeitar",
                            expired: "Expirada",
                            all_offers: "Todas as ofertas",
                            active_offers: "Ofertas ativas",
                            archived_offers: "Ofertas arquivadas"
                        },
                        orders: {
                            orders: "Encomendas",
                            order: "Encomenda",
                            my_orders: "Minhas encomendas",
                            shop_all: "Começar a comprar",
                            no_orders: "Ainda não efetuou nenhuma encomenda.",
                            order_number: "Nrº encomenda",
                            order_date: "Data encomenda",
                            total: "Total",
                            details: "Detalhes",
                            close_details: "Fechar detalhes da encomenda",
                            product: "produto",
                            shipping_data: "Shiping data",
                            delivery: "Delivery",
                            billing: "Billing",
                            products: "Produtos",
                            order_complete: "Order complete"
                        },
                        order_id: {
                            back_order: "Voltar",
                            order: "Encomenda",
                            package: "Pacote",
                            delivery_status: "Informações do envio",
                            status: "Estado",
                            shipped_by: "Transportado por",
                            delivery_date: "Data de entrega",
                            tracking_number: "Número de seguimento",
                            track_package: "Seguir encomenda",
                            leave_review: "Deixar comentário",
                            review: "Comentário",
                            save_review: "Guardar comentário",
                            delivery_address: "Morada de entrega",
                            billing_address: "Morada de faturação",
                            payment_information: "Dados pagamento",
                        }
                    },
                    login: {
                        check_inbox: "Verifique sua caixa de entrada",
                        forgot_password: "Esqueceu a sua senha?",
                        sign_in: "Entrar",
                        new_here: "Sou novo aqui",
                        register: "Registar",
                        error_register: "Erro ao registar",
                        back_to_sign: "Voltar ao login",
                        or: "OU",
                        reset_password: "Redefinir senha",
                        link_to_reset_password: "Insira o seu endereço de e-mail e enviaremos um link para redefinir sua senha",
                        create_new_account: "Crie uma nova conta de segunda mão.",
                        register_later: "Com pressa? Não tem problema, pode se registar mais tarde.",
                        continue_guest: "Continuar como convidado",
                        sent_email: "Enviamos um e-mail para o endereço fornecido. Não consegue encontrar? Verifique a sua pasta de lixo eletrônico.",
                        account_distinct: "Esteja ciente de que uma conta de <1>{{title}}</1> é distinta e independente da sua conta principal em <3>{{link}}</3>."
                    },
                    bag: {
                        shopping_bag: "Carrinho",
                        product_unavailable: "Produto indisponível, remova do carrinho",
                        offer_unavailable: "Oferta indisponível, remova do carrinho",
                        total: "Total",
                        taxes_not_included: "Taxas não incluídas",
                        continue_shopping: "Continuar a comprar",
                        go_to_checkout: "Finalizar compra",
                        empty_bag: "Ainda não há nada no seu carrinho....",
                        start_shopping: "Começar a comprar"
                    },
                    header: {
                        my_account: "Minha conta",
                        my_bag: "Meu carrinho",
                        sign_in: "Iniciar sessão",
                        sign_out: "Terminar sessão",
                        search_our_store: "Procurar na nossa loja",
                        search_prelove_item: "Procurar item em segunda mão",
                        search: "Procurar"
                    },
                    home: {
                        shop: "Comprar",
                        sell: "Vender",
                        view_all: "Ver todos os produtos",
                        discover_more: "Descubra mais",
                        shop_second_hand: "Comprar em segunda mão",
                        shop_blosers: "Shop beautiful losers"
                    },
                    shopping: {
                        filters: "Filtros",
                        filters_category: {
                            condition: "Condição",
                            price: "Preço",
                            collection: "Coleção",
                            category: "Categoria",
                            country: "País"
                        },
                        clear_filters: "Apagar filtros",
                        view_results: "Ver resultados",
                        no_products: "Desta vez não temos o que procura, mas pode ver as nossas novidades.",
                        no_products_preloved: "Não temos itens pre-loved para mostrar desta vez, mas por que não dar uma olhada na nossa coleção beautiful losers.",
                        new_arrivals: "Novidades",
                        sort_by: "Ordenar por",
                        sizes: "Tamanhos",
                        color_available: "cor disponível",
                        colors_available: "cores disponíveis",
                        latest_additions: "Últimos artigos adicionados"
                    },
                    product: {
                        seller: "Vendedor",
                        offer_accepted: "A sua oferta foi aceite.",
                        buy_offer: "Compre antes que o tempo acabe!",
                        original_price: "Preço original:",
                        size: "Tamanho",
                        color: "Cor",
                        alert_sizes: "Avise-me quando meu tamanho estiver disponível",
                        size_guide: "Guia de tamanhos",
                        condition: "Condição",
                        listed_by: "Vendido por",
                        buy_for: "Buy for",
                        add_to_bag: "Adicionar ao carrinho",
                        add_wishlist: "Adicionar aos favoritos",
                        remove_wishlist: "Remover dos favoritos",
                        item_added_to_cart: "Item adicionado ao carrinho",
                        view_bag: "Ver carrinho",
                        make_an_offer: "Fazer uma oferta",
                        quality_authentication: "Disponível para garantia de qualidade e autenticação",
                        seller_comments: "Comentários do vendedor",
                        details: "Detalhes",
                        how_it_works: "Como funciona",
                        how_it_works_content0: "Como comprador, será solicitado a escolher um ponto de recolha DHL Parcel ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        how_it_works_content1: "Como comprador, será solicitado a escolher um ponto de recolha DHL Parcel ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        how_it_works_content2: "Como comprador, será solicitado a escolher um ponto de recolha DHL Parcel ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        how_it_works_content3: "Como comprador, será solicitado a escolher um ponto de recolha DHL Parcel ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        how_it_works_content4: "Como comprador, será solicitado a escolher um ponto de recolha DHL Parcel ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        how_it_works_content5: "Como comprador, será solicitado a escolher um ponto de recolha DPD ou Post que seja mais conveniente para si durante o processo de finalização da compra. Depois de comprar um item, o vendedor deve enviá-lo para em até 5 dias úteis após a venda. Se quiser saber mais sobre como comprar um produto usado, consulte a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery: "Envio e entrega",
                        shipping_delivery_content0: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery_content1: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery_content2: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery_content3: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery_content4: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        shipping_delivery_content5: "O custo de entrega de produtos em segunda mão baseia-se principalmente na origem e destino do seu item. As taxas e prazos de entrega serão calculados automaticamente e informados na finalização da compra. Para mais detalhes sobre isso leia a nossa página <1>Perguntas frequentes</1>.",
                        transparency: "Transparência",
                        transparency_content: "Transparência clara de algodão. Quando você escolhe ISTO., o que você vê é exatamente o que você obtém. Isso significa que você tem uma compreensão clara de tudo o que foi necessário para fazer nossas roupas. Em primeiro lugar, só trabalhamos com fábricas que possam cumprir os mais elevados padrões de responsabilidade social e políticas de trabalho. Em segundo lugar, você verá exatamente quanto pagamos por cada componente, desde os materiais até a produção.",
                        new_arrivals: "Novidades",
                        alert_sizes_modal: {
                            alert_me_available: "Avise-me quando estiver disponível",
                            select_your_sizes: "Selecione o(s) tamanho(s) desejado(s)",
                            notify_sizes: "Iremos notificá-lo imediatamente por e-mail assim que o item estiver disponível no seu tamanho.",
                            alert_me: "Alertar-me"
                        },
                        offer_modal: {
                            make_an_offer: "Fazer uma oferta",
                            submit_purchase_proposal: "Envie uma proposta de compra ao vendedor para o item desejado.",
                            offer_price: "Preço",
                            no_offer_price: "Insira o preço da sua oferta",
                            invalid_offer_price: "Insira um preço entre {{min_offer}}{{currency}} e {{max_offer}}{{currency}}"
                        },
                        listed_by_from: "Vendido por <1>{{customer}}</1> de {{country}}"
                    },
                    vendor: {
                        shop_all: "Voltar para todos os produtos",
                        items_for_sale: " items para venda",
                        no_products: "não possui nenhum item disponível para venda."
                    },
                    new_arrivals: {
                        sizes: "Tamanhos",
                        color_available: "cor disponível",
                        colors_available: "cores disponíveis"
                    },
                    checkout: {
                        tabs: {
                            delivery: "Dados entrega",
                            review: "Revisão",
                            payment: "Pagamento"
                        },
                        view_summary: "Ver sumário",
                        empty_bag: "O seu carrinho está atualmente vazio!",
                        start_shopping: "Começar a comprar",
                        delivery_details: "Detalhes da entrega",
                        change_address: "Alterar morada",
                        add_address: "Adicionar morada",
                        edit_address: "Editar",
                        no_address: "Ainda não adicionou nenhuma morada. Por favor <1>insira uma morada</1>.",
                        no_delivery_address: "Insira a sua morada de entrega.",
                        same_delivery_billing: "O endereço de faturação é igual ao endereço de entrega",
                        billing_details: "Detalhes de faturação",
                        your_delivery_options: "Opções de entrega",
                        review_order: "Rever encomenda",
                        choose_another_address: "Escolher outra morada",
                        address_list: "Lista de moradas",
                        new_address: "Nova morada",
                        delivery_option_2: "Prefere receber o seu item imediatamente? Selecione esta opção para entrega direta na sua porta ou em um ponto de recolha conveniente. Seu item será enviado diretamente do vendedor, proporcionando uma experiência de compra rápida e descomplicada.",
                        delivery_option_X: "Ao escolher esta opção, nossos especialistas inspecionarão e autenticarão cuidadosamente as suas peças quanto à qualidade e autenticidade. Isso garante que você receba um item verificado e de alta qualidade que atenda aos nossos rigorosos padrões. Sua compra é respaldada por nossa garantia, proporcionando-lhe tranquilidade."
                    },
                    review: {
                        empty_bag: "O seu carrinho está atualmente vazio!",
                        start_shopping: "Começar a comprar",
                        delivery_address: "Morada de entrega",
                        billing_address: "Morada de faturação",
                        delivery_billing_address: "Morada de entrega e faturação",
                        your_delivery_options: "Opções de entrega",
                        sending_from: "Enviado de",
                        listed_by: "Vendido por",
                        confirm_order: "Confirmar encomenda",
                        apply: "Aplicar",
                        promo_code: "Código de desconto",
                        no_promo_code: "Insira um código de desconto válido.",
                        invalid_promo_code: "O código de desconto não é válido.",
                        shipping_fee: "Taxa de envio",
                        processing_fee: "Taxa de processamento",
                        choose_delivery: "Entrega na minha morada",
                        pickup_store: "Recolher num ponto pickup",
                        pickup_point: "Recolher num ponto pickup",
                        choose_delivery_option: "Escolha uma opção de entrega.",
                        proceed_payment: "Proceder para o pagamento",
                        pay_now: "Pagar agora",
                        loading: "A carregar",
                        change_pickup: "Alterar ponto de recolha",
                        pickup_points: "Pontos de recolha",
                        map: "Mapa",
                        choose_pickup_point: "Escolha um ponto de recolha",
                        points_near_me: "Encontrar pontos de recolha perto de mim"
                    },
                    logo: {
                        confirm_logo_title: "Tem a certeza que quer sair do checkout?",
                        confirm_logo_text: "Se sair do checkout, perderá todo o progresso que fez até agora.",
                        leave: "Sair",
                        cancel: "Cancelar"
                    }
                },
            }
        }
    });


export default i18n;